import './index.scss'
// 点击打开导航栏
let $menuEl = $('.comp-menu')
let $openMenuElArr = $('[data-open="menu"]')
$openMenuElArr.click(function () {
	if (this.classList.value.indexOf('sp_nav_se') >= 0) {
		$openMenuElArr.removeClass('active')
		$menuEl.removeClass('active')
		$('.hamburger-1').removeClass('sp_nav_se');
	} else {
		$openMenuElArr.addClass('active')
		$menuEl.addClass('active')
		$('.hamburger-1').toggleClass('sp_nav_se');
	}
})

// 点击打开二级栏目
$('.comp-menu-nav ul li i').click(function () {
	$(this).parent().children('ul').slideToggle().parent().siblings().children('ul').slideUp();
	$(this).toggleClass('active_icon');
	$(this).parent().siblings().find('i').removeClass('active_icon');
});

$('.comp-menu-wrapper-search').bind("click", function () {
	$(this).addClass("active").siblings().removeClass("active");
});