import './index.scss'
import 'jquery/dist/jquery.min'
$('.search_box .enter .input').bind("click", function () {
	$(this).addClass("active").siblings().removeClass("active");
});
// 屏幕滑动头部导航进行紧贴上边
let $headerEl = $('.comp-header')
$(window).on('scroll', function () {
	if (window.pageYOffset !== 0) {
		$headerEl.addClass('active')
	} else {
		$headerEl.removeClass('active')
	}
})
// 首页侧边悬浮
$(document).scroll(function () {
  let scroH = $(document).scrollTop();
  $('.float').css('top', scroH / 3 - 100 + 'px');
  $('.right-img').css('top', scroH / 2 + 200 + 'px');
});

//鼠标经过导航显示子栏目
$('.comp-header-nav ul li').hover(function () {
	$(this).children('.child_column').toggleClass('on');
	// $(this).children('.child_column').css("display","flex")
});

// 头部搜索和关闭
$(document).ready(function (e) {
	$(".comp-header-lang").bind("click", function () {
		$(".search_box").slideDown(300)
	})
	$(".close_btn").bind("click", function () {
		$(".search_box").slideUp(300)
	})
});

//头部子菜单效果
$('.child_column ul li').mouseenter(function () {
	$(this).find('.read_more .line').css({
	  'width': '100%',
	  'left': '0',
	  'right': 'auto'
	})
	// $(this).find('.menu_img img').css({
	//   'transform': 'scale(1.1)',
	// })
}).mouseleave(function(){
	$(this).find('.read_more .line').css({
	  'width': '0',
	  'right':'0',
	  'left':'auto'
	})
	// $(this).find('.menu_img img').css({
	//   'transform': 'scale(1)',
	// })
})