import './index.scss'
$(".dwtop").click(function () {
    $("body,html").animate({ scrollTop: "0" })
});

$('ul:nth-child(3) li dl dd').bind("click", function () {
    $(this).addClass("active").siblings().removeClass("active");
});


$(document).scroll(function () {
    let scroH = $(document).scrollTop(); //滚动高度
    let viewH = $(window).height(); //可见高度
    let contentH = $(document).height(); //内容高度

    //右侧滚动到顶部
    if (scroH < 1000) {
        $('.dwtop').fadeOut()
    } else {
        $('.dwtop').fadeIn()
    }
    if (contentH - (scroH + viewH) <= 380) { 
      $('.dwtop').addClass('foot-top')
    } else {
      $('.dwtop').removeClass('foot-top')
    }
});
