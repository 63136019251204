import './index.scss';

import webmap from 'jason-webmap';

// const log = debug('app:component:A');

webmap({
  // 激活 map 的按钮选择器
  openSelector: '[data-open="webmap"]',
  // 跟随者 map 一同移动的内容选择器
  moveSelector: '.comp-root'
});
