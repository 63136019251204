module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p +=
((__t = ( require('../../../node_modules/jason-webmap/template/index.ejs')() )) == null ? '' : __t) +
'\r\n';

}
return __p
}